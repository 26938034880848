body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
}

main {
  flex: 1 0 auto;
}

.content {
  height: 900px;
  margin-top: 200px;
  margin-bottom: 200px;
  background-image: url("../Pics/content.jpg");
  background-size: cover;
}

.content h1 {
  color: #114215;
  font-size: 2em;
  padding-top: 300px;
}

.content p {
  color: #114215;
  font-size: 1.2em;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 200px;
  background-image: url("../Pics/header.jpg");
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  padding-right: 20px;
}

header h1 {
  color: #114215;
  font-size: 3em;
  margin-left: 40px;
  margin-bottom: 2px;
}
header p {
  color: #114215;
  font-size: 1em;
  margin-left: 40px;
}

hr {
  border: none;
  border-top: 2px solid #114215; /* Change color as needed */
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-bottom: 40px;
}
